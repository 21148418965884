import { useEffect, useState } from "react";
import { useConfig } from "./hooks";
import "./LoginBox.css";
import { isWeixin, win, isMobile, query, LOGINURL } from "./utils";
import { PasswordLogin } from "./PasswordLogin";

const types = query.type?.split(",");

export default function LoginBox({ dialog, onSuccess }) {
  const [appid, redirectUri, state] = useConfig();
  const [key, setKey] = useState("1");
  const [type, setType] = useState(isMobile && types ? "pwd" : "weixin");

  useEffect(() => {
    if (!appid) return;
    if (!isWeixin && !isMobile) {
      new win.WxLogin({
        self_redirect: true,
        id: "weixin_login",
        appid,
        scope: "snsapi_login",
        redirect_uri: redirectUri,
        state,
        // style: "black",  // 提供"black"、"white"可选，默认为黑色文字描述
      });
    }
  }, [appid, redirectUri, state, key]);

  useEffect(() => {
    if (!appid || !isWeixin) return;
    const params = {
      appid,
      redirect_uri: redirectUri,
      response_type: "code",
      scope: "snsapi_userinfo",
      state: state || "",
    };
    const url =
      "https://open.weixin.qq.com/connect/oauth2/authorize?" +
      new URLSearchParams(params) +
      "#wechat_redirect";
    // win.location.href = url;
  }, [appid, redirectUri, state, key, dialog]);

  useEffect(() => {
    let sent = false;
    const handle = (event) => {
      if (
        event.origin === LOGINURL && //确认消息来自于自己的域名 Make sure the message posted from your own origin
        !event.data.source && //过滤掉一些调试组件发来的信息  Filter out some devtools message
        !sent //避免多次运行回调函数  Avoid callback twice
      ) {
        sent = true;
        const ret = JSON.parse(event.data);
        if (ret.errcode) {
          alert(ret.errmsg);
          setKey(new Date().getTime().toString());
          return;
        }
        onSuccess(ret);
      }
    };
    win.addEventListener("message", handle);
    return () => {
      win.removeEventListener("message", handle);
    };
  }, [onSuccess]);

  return isWeixin ? (
    dialog ? null : (
      <div className="login_info" onClick={() => setKey(new Date().getTime())}>
        请登录...
      </div>
    )
  ) : (
    <div className="login_box">
      {types && !isMobile && (
        <div
          className="login_box_switch"
          onClick={() => setType(type === "weixin" ? "pwd" : "weixin")}
        >
          <div
            className={"login_box_btn "}
            title={type === "pwd" ? "微信登录" : "密码登录"}
          >
            <div className={"btn " + type}></div>
          </div>
        </div>
      )}
      {!isMobile ? (
        <div
          id="weixin_login"
          style={{ display: type === "weixin" ? "block" : "none" }}
        ></div>
      ) : (
        <div style={{ display: type === "weixin" ? "block" : "none" }}>
          请使用微信打开
        </div>
      )}
      {types?.includes("pwd") ? (
        <PasswordLogin
          style={{ display: type === "pwd" ? "block" : "none" }}
          onSuccess={onSuccess}
        />
      ) : null}
      {types && isMobile && (
        <div className="login_box_switch2">
          {type === "pwd" && <button className={"btn pwd"} onClick={()=>setType('weixin')}></button>}
          {type === 'weixin' && <button className={"btn weixin"} onClick={()=>setType('pwd')}></button>}
        </div>
      )}
    </div>
  );
}
