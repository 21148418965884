export function getUrlParams(url) {
  const params = {};
  url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (match, key, value) {
    params[key] = decodeURIComponent(value);
  });
  return params;
}

export function objectToQueryString(obj) {
  return Object.keys(obj)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join("&");
}

export const win = global;
export const query = getUrlParams(win.location.href);
export const HOME = "https://jianguoke.cn";

export function getLogo() {
  if (query.from === "code") {
    return "code";
  }
  return "logo";
}

export const LOGIN_PROVIDER =
  process.env.NODE_ENV === "production" ? "" : "dev";
export const LOGINURL =
  // process.env.NODE_ENV === "production"
  "https://login-server.jianguoke.cn";
// : "http://localhost:4001";
export const ua = win.navigator.userAgent.toLowerCase();
export const isWeixin = ua.indexOf("micromessenger") !== -1;

export function getDeviceType() {
  const min = Math.min(win.screen.width, win.screen.height);
  if (min < 576) {
    return "Mobile";
  } else if (min < 1080) {
    return "Tablet";
  } else {
    return "PC";
  }
}

export function getPlatform() {
  if (/android/i.test(ua)) {
    return "Android";
  }

  // 检查是否为 iOS 设备（包括 iPhone, iPad, iPod）
  if (/ipad|iphone|ipod/.test(ua) && !win.MSStream) {
    return "iOS";
  }

  return "Unknown";
}

export const isMobile =
  getDeviceType() === "Mobile" && getPlatform() !== "Unknown";

export function getOrigin() {
  const provider = query.from;
  if (provider === "code") {
    return "https://code.jianguoke.cn";
  } else if (provider === "codereport") {
    return "https://code-report.jianguoke.cn";
  } else if (provider === "codeadmin") {
    return "https://code-admin.jianguoke.cn";
  } else if (provider.startsWith("local")) {
    return "http://localhost:" + provider.substring(5);
  }

  return "https://login.jianguoke.cn";
}
