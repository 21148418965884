import React from "react";
import "./index.css";
import ReactDOM from "react-dom/client";
import LoginPage from "./LoginPage";
import LoginDialog from "./LoginDialog";
import Policies from "./Policies"; 
import NoMatch from "./NoMatch";
import { query } from "./utils";
import { BrowserRouter, Routes, Route } from "react-router-dom";
const root = ReactDOM.createRoot(document.getElementById("root"));

console.log(
  `
      __ _____ _____ _____ _____ _____ _____ _____ _____ 
   __|  |     |  _  |   | |   __|  |  |     |  |  |   __|
  |  |  |-   -|     | | | |  |  |  |  |  |  |    -|   __|
  |_____|_____|__|__|_|___|_____|_____|_____|__|__|_____|
  `
);

root.render(
  <React.StrictMode>
    {query.dialog ? (
      <LoginDialog />
    ) : (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginPage></LoginPage>} index />
          <Route path="/policies/*" element={<Policies></Policies>}></Route>
          <Route path="*" element={<NoMatch></NoMatch>} />
        </Routes>
      </BrowserRouter>
    )}
  </React.StrictMode>
);
