import LoginBox from "./LoginBox";
import "./LoginDialog.css";
import { query, win, isWeixin, isMobile, getOrigin } from "./utils";

const loginRes = (res) => {
  win.parent.postMessage(res.token, getOrigin());
};
const closeBox = () => {
  if (query.close === 'false') {
    return;
  }
  win.parent.postMessage("close", getOrigin());
};

export default function LoginDialog() {
  if (isWeixin) return <LoginBox dialog onSuccess={loginRes}></LoginBox>;

  return (
    <>
      {query.close !== 'false' && (
        <div className="login_dialog_close" onClick={closeBox}></div>
      )}
      <div className="login_dialog" onClick={closeBox}>
        <LoginBox onSuccess={loginRes} dialog></LoginBox>
      </div>
      {query.close !== 'false' && (
        <div className="login_dialog_mask" onClick={closeBox}></div>
      )}
    </>
  );
}
