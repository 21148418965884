import React from "react";
import "./PasswordLogin.css";
import axios from "axios";
import sha256 from 'crypto-js/sha256';
import { LOGINURL } from "./utils";
const api = axios.create({ baseURL: LOGINURL });

async function login(username, password) {
  try {
    const hashDigest = sha256(password); 
    const res = await api.post("/login", {
      username,
      password: hashDigest.toString(),
    });
    return res?.data;
  } catch (err) {
    alert(err?.response?.data.message);
  }
}

export function PasswordLogin(props) {
  const [password, setPassword] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [agree, setAgree] = React.useState(false);
  const ref = React.useRef();

  return (
    <div className="login_pwd" style={props.style}>
      <p>密码登录</p>
      <div className="box">
        <div>
          <input
            autoFocus
            type="text"
            value={username}
            placeholder="请输入用户名"
            onChange={(e) => setUsername(e.target.value)}
          ></input>
        </div>
        <div>
          <input
            type="password"
            value={password}
            placeholder="请输入密码"
            onChange={(e) => setPassword(e.target.value)}
          ></input>
        </div>
        <div>
          <label ref={ref}>
            <input
              type="checkbox"
              checked={agree}
              onChange={(e) => setAgree(e.target.checked)}
            ></input>
            我已阅读并且同意
            <a
              href="https://login.jianguoke.cn/service_agreement.html"
              target="_blank"
            >
              《服务协议》
            </a>
            和
            <a
              href="https://login.jianguoke.cn/privacy_policy.html"
              target="_blank"
            >
              《隐私政策》
            </a>
          </label>
        </div>
        <div>
          <button
            onClick={async () => {
              if (!agree) {
                // 添加shake类来触发动画
                ref.current.classList.add("shake");

                // 动画结束后移除shake类，以便下次点击时可以再次触发动画
                ref.current.addEventListener(
                  "animationend",
                  function handleAnimationEnd(event) {
                    if (event.animationName === "shake") {
                      ref.current.removeEventListener(
                        "animationend",
                        handleAnimationEnd
                      );
                      ref.current.classList.remove("shake");
                    }
                  },
                  { once: true }
                ); // 使用{ once: true }确保事件监听器只触发一次
                return;
              }
              const token = await login(username, password);
              if (token) {
                props.onSuccess(token);
              }
            }}
          >
            登录
          </button>
        </div>
      </div>
    </div>
  );
}
