import { useEffect, useState } from "react";
import axios from "axios";
import { isWeixin, LOGINURL, LOGIN_PROVIDER } from "./utils";

export function useConfig() {
  const [appid, setAppid] = useState("");
  const [redirectUri, setServerUrl] = useState("");
  const [state, setState] = useState("");

  useEffect(() => {
    axios
      .get(LOGINURL + "/config" + (isWeixin ? "?app=mp" : ""), {
        withCredentials: true,
      })
      .then((res) => {
        setState(res.data.state);
        setServerUrl(
          res.data.serverUrl + (LOGIN_PROVIDER ? "/" + LOGIN_PROVIDER : "")
        );
        setAppid(res.data.appid);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return [appid, redirectUri, state];
}
